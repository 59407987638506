import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

// TODO: Investigate the `resetPagination` function causing requests looping when it's added to the useEffect dependency
export function usePaginatedApi(requestFn, id = null, params = {}, fetchWithInterval) {
  const [items, setItems] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [page, setPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cursor, setCursor] = useState(new Date());
  const [error, setError] = useState(null);

  // Refetching logic after resetting pagination
  const [refetch, setRefetch] = useState(false);

  // Axios cancellable pending requests
  const cancelRequestRef = useRef(null);

  const cancelPendingRequests = () => {
    if (cancelRequestRef.current) {
      cancelRequestRef.current('Request cancelled.');
    }
  };

  // Cancel any pending requests when unmounting
  useEffect(() => {
    return cancelPendingRequests;
  }, []);

  // Refetch data when explicitly requested (side effect of resetPagination)
  useEffect(() => {
    if (refetch) {
      loadMore();
      setRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const fetchData = callback => {
    setLoading(true);

    const { CancelToken } = axios;
    const source = CancelToken.source();

    cancelRequestRef.current = source.cancel;

    // NOTE: cancelationToken needs to be passed to individual request as a config parameter.
    const axiosHeaders = {
      cancelToken: source.token,
    };

    const body = { cursor, id, page, ...params };
    requestFn(body, axiosHeaders)
      .then(res => {
        callback(res);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setError(error?.response);
          // this should be added too, but it causes additional re-renders that I'm not willing to debug now.
          // setLoading(false);
          throw new Error(error);
        }
      });
  };

  const handleResponse = ({
    data: {
      links,
      data = {},
      meta: { current_page } = {}, // page meta info
      meta,
      ...additionalMetadata
    } = {},
  } = {}) => {
    if (initialLoading) setInitialLoading(false);
    setPage(current_page + 1);
    // MTS - Queued for removal. Temporarily commenting out because we are switching to checking
    // links.next instead of total_pages.
    // setHasMore(current_page < total_pages);
    setHasMore(links && !!links.next);
    setItems([...items, ...data]);
    setMetadata({
      ...meta,
      ...additionalMetadata,
    });
    setLoading(false);
  };

  const loadMore = () => {
    if (!hasMore) {
      setLoading(false);
      return;
    }

    fetchData(handleResponse);
  };

  const resetPagination = useCallback(({ refetch } = {}) => {
    setItems([]);
    setPage(1);
    setInitialLoading(true);
    setLoading(false);
    setHasMore(true);
    setCursor(new Date());

    if (refetch) {
      cancelPendingRequests();
      setRefetch(true);
    }
  }, []);

  // Use this if you want to emulate live updates
  useEffect(() => {
    if (!fetchWithInterval) return;

    const intervalId = setInterval(() => {
      resetPagination({ refetch: true });
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchWithInterval, resetPagination]);

  // TODO: deprecate array return type - it's easy to skip return value and is error prone
  return [
    hasMore,
    initialLoading,
    items,
    loadMore,
    loading,
    setItems,
    resetPagination,
    metadata,
    cancelPendingRequests,
    error,
  ];
}

export function toObject(paginationArray) {
  const [
    hasMore,
    initialLoading,
    items,
    loadMore,
    loading,
    setItems,
    resetPagination,
    metadata,
    cancelPendingRequests,
    error,
  ] = paginationArray;

  return {
    cancelPendingRequests,
    error,
    hasMore,
    initialLoading,
    items,
    loading,
    loadMore,
    metadata,
    resetPagination,
    setItems,
  };
}
