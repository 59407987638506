import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/theme';

const CenterWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;

// eslint-disable-next-line
export const Spinner = ({ color }) => (
  <Spin indicator={<LoadingOutlined style={{ color: colors[color], fontSize: 24 }} spin />} />
);

const Loading = ({ centered, color }) => {
  return centered ? (
    <CenterWrap>
      <Spinner color={color} />
    </CenterWrap>
  ) : (
    <Spinner color={color} />
  );
};

Loading.propTypes = {
  centered: PropTypes.bool,
  color: PropTypes.string,
};

Loading.defaultProps = {
  centered: false,
  color: 'coral',
};

export default Loading;
