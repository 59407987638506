import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { bool, func, node, string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../styles/theme';

const ImpactiveButtonInner = styled.button`
  background: ${({ $background }) => $background ?? theme.colors.darkBlue};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  padding: 6px 16px;
  width: ${props => (props.block ? '100%' : '')};
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    color: ${theme.colors.white};
  }

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ isActivityButton }) =>
    isActivityButton &&
    css`
      width: 122px;
      height: 25px;
    `}

  &:active {
    opacity: 0.5;
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }
  }

  ${({ secondary }) =>
    secondary &&
    css`
      border: 1px solid #d9d9d9;
      background: ${theme.colors.white};
      svg {
        color: ${theme.colors.blue};
      }
    `}

  ${({ coral }) =>
    coral &&
    css`
      background: ${theme.colors.coral};
      svg {
        color: ${theme.colors.black};
      }
    `}

  ${({ teal }) =>
    teal &&
    css`
      background: ${theme.colors.teal};
      svg {
        color: ${theme.colors.black};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${theme.colors.lightGray};
      cursor: not-allowed;
      border: solid #d9d9d9 1px;
      opacity: 0.5;
    `}

  ${({ color }) =>
    color === 'coral' &&
    css`
      background-color: ${theme.colors.coral};
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 1.25em;
      height: 35px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      font-size: 1em;
      height: 42px;
    `}

  ${({ size }) =>
    size === 'xlarge' &&
    css`
      font-size: 1.4em;
      height: 50px;
    `}

  ${({ size }) =>
    size === 'full-width' &&
    css`
      font-size: 16px;
      height: 48px;
      width: 100%;
    `}

  ${({ size }) =>
    size === 'large-square' &&
    css`
      font-size: 1em;
      height: 36px;
      width: 36px;
      svg {
        margin-right: 0;
      }
    `}
`;

const ButtonText = styled.div`
  color: ${theme.colors.white};
  font-family: ${theme.fonts.regular};
  text-align: center;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75;
  * &:hover {
    text-decoration: none;
  }

  // Remove Safari (<16) issue where it adds button like styles to this component
  &&& {
    -webkit-appearance: none !important;
  }

  ${({ size }) =>
    size === 'medium' &&
    css`
      font-family: ${theme.fonts.bold};
    `}

  ${({ size }) =>
    size === 'full-width' &&
    css`
      font-size: 16px; // not using rem/em here, because need this to be real 16px on mobile
      font-weight: 700;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.darkBlue};
      cursor: not-allowed !important;
    `}
    ${({ secondary }) =>
    secondary &&
    css`
      color: ${theme.colors.blue};
    `}

    ${({ teal }) =>
    teal &&
    css`
      color: ${theme.colors.black};
    `}

    ${({ color }) =>
    color === 'coral' &&
    css`
      color: ${theme.colors.white};
    `}
`;

const ImpactiveButton = ({ children, onClick, loading, icon, ...rest }) => {
  return (
    <ImpactiveButtonInner onClick={onClick} {...rest}>
      {icon}
      <ButtonText {...rest}>
        {loading && <LoadingOutlined spin className="mr-2" />}
        {children}
      </ButtonText>
    </ImpactiveButtonInner>
  );
};

ImpactiveButton.defaultProps = {
  block: false,
  children: null,
  disabled: false,
  icon: null,
  isActivityButton: false,
  loading: false,
  secondary: false,
  size: 'regular',
};

ImpactiveButton.propTypes = {
  block: bool,
  children: node,
  disabled: bool,
  icon: node,
  isActivityButton: bool,
  loading: bool,
  onClick: func,
  secondary: bool,
  size: string,
};

export const ImpactiveTextButton = styled(Button)`
  &&& {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 100%;
    height: min-content;
    text-decoration: underline;
    color: ${theme.colors.blackL32};
    svg {
      margin-left: 5px;
      color: ${theme.colors.blackL32};
    }
    &:hover {
      svg {
        color: ${theme.colors.coral};
      }
      background: transparent;
      text-decoration: underline;
      color: ${theme.colors.coral};
    }
  }
`;

export default ImpactiveButton;
