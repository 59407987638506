import * as DOMPurify from 'dompurify';

const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

const dompurify = DOMPurify;

dompurify.addHook('beforeSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    if (!node.hasAttribute('target')) {
      node.setAttribute('target', '_self');
    }

    if (node.hasAttribute('target')) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'));
    }
  }
});

dompurify.addHook('afterSanitizeAttributes', function (node) {
  if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE));
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener');
    }
  }
});

export const sanitize = string => dompurify.sanitize(string);
